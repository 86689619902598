export const ExamplesList = [
  {
    id: "1",
    image: "https://vidyagiri.com/tallgrass-samples/site/sketch.png",
    label: "on paper: sketching",
    description: "sketch samples can include: pencil + paper, watercolor, abstracted forms, doodles",
    examples: [
      { title: "watercolor", id: "001", image: "https://vidyagiri.com/tallgrass-samples/samples/001.jpeg" },
      { title: "pen + paper", id: "003", image: "https://vidyagiri.com/tallgrass-samples/samples/002.jpeg" },
      { title: "abstracted forms", id: "002", image: "https://vidyagiri.com/tallgrass-samples/samples/003.jpeg" },
    ],
  },

  {
    id: "2",
    image: "https://vidyagiri.com/tallgrass-samples/site/write.png",
    label: "on paper: written reflection",
    description: "written forms can be: poems, paragraphs, bullet points, fragmented thoughts",
    examples: [
      { title: "poem", id: "", image: "" },
      { title: "thoughts", id: "", image: "" },
      { title: "bullet points", id: "", image: "" },
    ],
  },

  {
    id: "3",
    image: "https://vidyagiri.com/tallgrass-samples/site/digital.png",
    label: "with a digital device: photo, video, audio",
    description: "you can use your device to capture photo, video, or sound recordings of your surroundings",
    examples: [
      { title: "photo", id: "006", image: "https://vidyagiri.com/tallgrass-samples/samples/006.jpeg" },
      { title: "video", id: "005", image: "https://vidyagiri.com/tallgrass-samples/samples/005.jpg" },
      { title: "sound recording", id: "004", image: "https://vidyagiri.com/tallgrass-samples/samples/004.jpg" },
    ],
  },
];
