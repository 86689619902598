import { createStyles, Container, Text, Group, SimpleGrid, rem, Button } from "@mantine/core";
import * as React from "react";

// const image = require("../../data/computer_surfing_md_clr.gif");
const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: rem(100),
    paddingBottom: rem(40),
  },

  title: {
    // fontWeight: 900,
    fontSize: rem(34),
    marginBottom: theme.spacing.md,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(32),
    },
  },

  control: {
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
    },
  },

  mobileImage: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  desktopImage: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
}));

export function ContributePage() {
  const { classes } = useStyles();

  return (
    <Container className={classes.root}>
      <SimpleGrid spacing={80} cols={2} breakpoints={[{ maxWidth: "sm", cols: 1, spacing: 40 }]}>
        <div>
          <Group sx={{ height: "100%" }} spacing={20}>
            <Text className={classes.title}>contribute your samples from the workshop!</Text>
            <Text size="sm">
              once you are done collecting your samples, you can contribute to this website by uploading your files to the form below.
              <br /> <br />
              submitted samples will be added to this website within the next week and Vidya will email you the link once it is available!
              <br></br>
              <br></br>
            </Text>
            {/* <Text size="sm"> */}
            <a href="https://forms.gle/DjEAP8RRLBWirwPC7" target="_blank">
              <Button variant="gradient" gradient={{ from: "#9b9974", to: "#9b9974", deg: 35 }} size="sm">
                upload samples
              </Button>
            </a>
            {/* </Text> */}
            <Text size="xl"></Text>
          </Group>
        </div>
        {/* <Image src={image} className={classes.mobileImage} />
        <Image src={image} className={classes.desktopImage} /> */}
      </SimpleGrid>
    </Container>
  );
}
