import { Carousel } from "@mantine/carousel";
import { useMediaQuery } from "@mantine/hooks";
import { createStyles, Paper, rem, useMantineTheme } from "@mantine/core";
import * as React from "react";

const useStyles = createStyles((theme) => ({
  card: {
    height: rem(200),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 900,
    color: theme.white,
    lineHeight: 1.2,
    fontSize: rem(32),
    marginTop: theme.spacing.xs,
  },

  category: {
    color: theme.white,
    opacity: 0.7,
    fontWeight: 700,
    textTransform: "uppercase",
  },
}));

interface CardProps {
  image: string;
  caption: string;
}

function Card({ image, caption }: CardProps) {
  const { classes } = useStyles();

  return <Paper shadow="md" p="xs" radius="md" sx={{ backgroundImage: `url(${image})` }} className={classes.card}></Paper>;
}

export function CardsCarousel(props) {
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const slides = props.pictureData.map((item) => (
    <Carousel.Slide key={item.caption}>
      <Card {...item} />
    </Carousel.Slide>
  ));

  return (
    <Carousel
      // height="40rem"
      slideSize="11rem"
      // breakpoints={[{ maxWidth: "xs", slideSize: "100%", slideGap: rem(5) }]}
      slideGap={rem(10)}
      align="start"
      slidesToScroll={mobile ? 1 : 2}
    >
      {slides}
    </Carousel>
  );
}
