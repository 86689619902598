import { createStyles, Button, Container, Text, rem, Card } from "@mantine/core";
import * as React from "react";
import { AccordionDemo } from "./AccordionDemo";
import { StepsList } from "./StepsList";
import { ExamplesList } from "./ExamplesList";

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: rem(100),
    paddingBottom: rem(40),
  },

  card: {
    // opacity: "0",
    width: "25rem",
    // height: "10rem",
    background: "rgba(255,255,255,20)",
    transition: "transform 150ms ease, box-shadow 150ms ease",

    "&:hover": {
      transform: "scale(1.01)",
      boxShadow: theme.shadows.md,
    },
  },
  title: {
    // fontWeight: 900,
    fontSize: rem(34),
    marginBottom: theme.spacing.md,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(32),
    },
  },

  control: {
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
    },
  },

  body: {
    color: "#545122",
  },

  mobileImage: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  desktopImage: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
}));

const prompts = [
  "look closely at the ground, what do you see?",
  "try to find the farthest thing that your eyes can perceive, imagine what exists beyond this",
  "what colors do you see? can you represent them through your sample?",
  "what smells do you notice? can you represent them through your sample?",
  "what sounds can you hear? can you depict them through your sample?",
  "look at the sky, what do you see?",
  "what textures surround you?",
  "find a shape that interests you in your surroundings and try to capture it in a transformed form",
  "try to record the path of a moving object, creature, or plant",
  "find a nearby piece of text from your surroundings and write a poem from these found words",
  "imagine what your surroundings might be like through different seasons",
  "take a sample of something very small",
  "take a sample of something very large",
  "take a sample of something that is low to the ground",
  "take a sample of something that is located above your head",
  "take a sample of something  that is rough",
  "take a sample of something that is smooth",
  "take a sample of something that is crunchy",
  "look around outside and find a plant, what do its leaves look like",
  "look around outside and find a plant, imagine what its roots might look like",
  "look at the ground and find a rock, what does its shape look like?",
  "look at the sky and find a cloud, what does its shape look like?",
  "try to find a bird, notice what it's doing and where it's going",
  "try to find a bird, how does it interact with its surroundings?",
  "try to find a bug, how does it interact with its surroundings?",
  "try to find a bug, notice what it's doing and where it's going",
  "take a sample of something that is green",
  "take a sample of something that is yellow",
  "take a sample of something that is orange",
  "take a sample of something that is white",
  "take a sample of something that is blue",
  "take a sample of something that is brown",
  "make a map of your surroundings. represent points of interest to you on your map",
  "look closely at a flower, what patterns do you notice?",
  "look closely at a leaf, what patterns do you notice?",
  "look closely at the branches of tree, what patterns do you notice?",
  "look far at the hills, what patterns do you notice?",
  "look at the stem of a plant, how are leaves and other parts arranged on the plant?",
];

function sample(list) {
  return list[Math.floor(Math.random() * list.length)];
}

export function WorkshopPage() {
  const { classes } = useStyles();
  const [promptText, setPromptText] = React.useState(sample(prompts));
  function handleClick(e) {
    e.preventDefault();
    setPromptText(sample(prompts));
    console.log("You clicked submit.");
  }
  return (
    <Container className={classes.root}>
      <div>
        <Text className={classes.title}>workshop instructions</Text>

        <Text size="sm">
          <a href=" https://vidyagiri.com/tallgrass-samples/site/sample-your-surroundings-workshop-sheet.pdf">
            download instructions as pdf
          </a>
        </Text>
        <br />

        <Text size="xl">introduction</Text>
        <br />
        <Text size="sm">
          "A good place to begin this journey of discovery is with a timeless quote from noted environmentalist Rachel Carson’s 1964 book,
          <i>The Sense of Wonder</i>, which can help bring focus to this and many other excursions into the natural world: <br /> <br />{" "}
          <i>
            Exploring nature...is largely a matter of becoming receptive to what lies all around you. It is learning again to use your eyes,
            ears, nostrils, and fingertips, using your senses. For most of us, knowledge of our world comes largely through sight, yet we
            look about with such unseeing eyes that we are partially blind. One way to open your eyes to unnoticed beauty is to ask
            yourself: What if I had never seen this before? What if I knew I would never see it again?"
          </i>
          <br />
          <br />
        </Text>
        <Text size="xs">- quoted from tour transcript by Eric Patterson, Lead Park Ranger, Tallgrass Prairie NP</Text>
        <br />
        <Text size="sm">
          In this workshop, we will be observing our surroundings and "sampling" from them to create our own personal reflections of our
          observations. Some ways we can "sample" are by making drawings, taking photos or videos or sounds, or writing reflections. The
          ways to observe and reflect are limitless and are personal to you, so feel free to reflect in your own way and let Vidya know if
          you need any help or just want to share ideas or thoughts.
        </Text>
        <br />
        <Text size="xl">workshop steps</Text>
        <br />
        <Text size="sm">
          You can sample from your surroundings through these steps, however feel free to explore and discover your own methods outside of
          what is listed here!
        </Text>
        <br />
      </div>

      <div>
        <AccordionDemo data={StepsList} />
      </div>

      <div>
        <br />
        <Text size="xl">examples of samples</Text>
        <br />

        <Text size="sm">
          Here are some examples of some samples that you can use for inspiration or ideas! Clicking on each card takes you to each sample's
          page.
        </Text>
        <br />

        <div>
          <AccordionDemo data={ExamplesList} />
        </div>
        <br />
        <Text size="xl">prompts</Text>
        <br />
        <Text size="sm">your random prompt is:</Text>
        <br />
        <Card p="xl" radius="md" component="a" className={classes.card}>
          <Text className={classes.body} id="random-prompt" size="sm">
            {promptText}
          </Text>
        </Card>
        <br />

        <Button onClick={handleClick} variant="gradient" gradient={{ from: "#9b9974", to: "#9b9974", deg: 35 }} size="sm">
          get another prompt!
        </Button>
        <br />
        <br />
      </div>
    </Container>
  );
}
