export const fieldLogData = {
  "6/09/2023": {
    day: "friday",
    journal:
      " arrived in matfield green with amma after a 11ish hr drive from houston, met sandra and sidney. watched the sunset from the patio behind the station and watched the train roar by several times and listened to the songs of the grassland birds that live here.",
    pictures: [
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-09-2023/IMG_4642.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-09-2023/IMG_4647.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-09-2023/IMG_4653.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-09-2023/IMG_4654.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-09-2023/IMG_4659.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-09-2023/IMG_4665.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-09-2023/IMG_4667.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-09-2023/IMG_4668.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-09-2023/IMG_4675.jpeg",
        caption: " ",
      },
    ],
  },
  "6/10/2023": {
    day: "saturday",
    journal:
      "in the morning, i met many kind people at the school for rural culture and creativity (including derek, katherine, felix, tia, matt, elliot, lida + more). after this, we explored the prairy art path and its surroundings. in the evening, we experienced the symphony in the the flint hills at wade pasture thanks to spare tickets from tia/matt and sandra. it was nice to chat with sandra about her practice and her stories during the drive over there and back and how she researches and documents and portrays. also greatly enjoyed chatting with tia and elliot about many different things throughout the day and while we were waiting in the pasture such as dragons, minecraft, astronomical units, and jackfruit. i learned a lot about rancher culture and different grasses and plants from various experts who were taking visitors on tours of the prairie and sharing their knowledge and experiences of the land.",
    pictures: [
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-10-2023/IMG_4679.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-10-2023/IMG_4686.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-10-2023/IMG_4687.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-10-2023/IMG_4690.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-10-2023/IMG_4693.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-10-2023/IMG_4698.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-10-2023/IMG_4720.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-10-2023/IMG_4721.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-10-2023/IMG_4730.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-10-2023/IMG_4489.JPEG",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-10-2023/IMG_4517.JPG",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-10-2023/IMG_4735.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-10-2023/IMG_4737.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-10-2023/IMG_4749.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-10-2023/IMG_4771.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-10-2023/IMG_4794.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-10-2023/IMG_4820.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-10-2023/IMG_4852.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-10-2023/IMG_4853.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-10-2023/IMG_4876.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-10-2023/IMG_4889.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-10-2023/IMG_4894.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-10-2023/IMG_4895.jpeg",
        caption: " ",
      },
    ],
  },
  "6/11/2023": {
    day: "sunday",
    journal:
      "had a slow, cloudy morning and ate some upma and pasta. then visited the tallgrass prairie and met the wonderful park rangers there (mary, eric, andrew). we got lucky and made it into the last group van tour of the day. eric took our group into the preserve and we saw the wide expanse of prairie with clouds rolling by and the herd of ~70 bison that reside here. we also explored the complex of various limestone buildings that were a part of the spring hill ranch complex.",
    pictures: [
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-11-2023/IMG_4916.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-11-2023/IMG_4920.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-11-2023/IMG_4925.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-11-2023/IMG_4940.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-11-2023/IMG_4942.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-11-2023/IMG_4951.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-11-2023/IMG_4966.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-11-2023/IMG_4978.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-11-2023/IMG_4995.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-11-2023/IMG_4996.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-11-2023/IMG_5019.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-11-2023/IMG_5020.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-11-2023/IMG_5042.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-11-2023/IMG_5068.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-11-2023/IMG_5074.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-11-2023/IMG_5095.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-11-2023/IMG_5096.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-11-2023/IMG_4701.JPG",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-11-2023/IMG_4705.JPG",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-11-2023/IMG_5102.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-11-2023/IMG_5103.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-11-2023/IMG_5104.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-11-2023/IMG_5105.jpeg",
        caption: " ",
      },
    ],
  },
  "6/12/2023": {
    day: "monday",
    journal:
      "in the morning i worked on this log (to add text/pictures for each day) and the samples viewer for the workshop, which can now display image, video, and audio samples! after lunch, i went for a drive to town and the preserve, stopping at the scenic overlook and another spot to admire the landscape and the hills. at the preserve, i painted a little sketch and recorded some photos and video. afterwards, i picked up an extension cord at a local home store and also visited dollar general which sells many grocery items here too. once home, i worked some more, then ate dinner and went for a walk with amma by the nearby trail on the other side of the train tracks. on our way back, we ran into bill, who was kind enough to give us a tour of his studio and home along with his son, lake. it was interesting to see all of the found materials he uses for his sculpture and the map pieces he is currently working on! and we also got to see the aquaponics system with goldfish that they had set up too! once we got back, i worked on installing my projector on the back patio to project on the station using the new extension cord. the exterior has a great limestone brick exterior which works very well with projection. i set up a (pretty rudimentary) slit scan that had an interesting effect of showing the basic colors of the land and sky and then the forms of the train when it passed by. unfortunately, a train did not pass by until dusk but it was really interesting to see when it finally did, reacting with the sounds and silhouettes of the train and the last traces of light in the sky at dusk.",
    pictures: [
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-12-2023/IMG_5117.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-12-2023/IMG_5122.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-12-2023/IMG_5132.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-12-2023/IMG_5140.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-12-2023/IMG_5167.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-12-2023/IMG_5178.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-12-2023/IMG_5197.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-12-2023/IMG_5213.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-12-2023/IMG_4722.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-12-2023/IMG_5249.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-12-2023/IMG_5250.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-12-2023/IMG_5258.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-12-2023/IMG_5269.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-12-2023/IMG_5273.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-12-2023/IMG_5297.jpg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-12-2023/IMG_5298.jpg",
        caption: " ",
      },
    ],
  },
  // (to be written *:･ﾟ✧*:･ﾟ✧)
  "6/13/2023": {
    day: "tuesday",
    journal:
      "spent most of the morning (and various times throughout the day) taking recordings of the trains going by the station. it took quite a few tries to get the recording process correct, but it was nice when i was able to get it right! then in the afternoon, went to the tallgrass preserve to explore a little more and watched the grass and barn swallows for a while. when i got back i did some more train recordings and editing. then went for a walk down the trail, taking some photos, and ran into bill who was returning the landmower. he told me that they were knocking down an old house that started to tilt (which belongs to teresa and jim) so we went to watch with some other folks from the town. after all the excitement, i walked back and spent some time going through and editing my recordings and then set up the projector to project the colors of the sky and grass and trains passing by until the sun went down. i made a small site with my recordings here: https://vidyagiri.com/grasstrainsky/ and might expand upon this more so the videos shuffle and have more interaction. overall, i like the simple contrast between the ambient sound of birds/wind and the mechanical buzzes/rumbles when the train arrives. it's also satisfying to see the train (and clouds!) come in and out of the frame, simplified and semi-abstracted to basic blocks of color.",
    pictures: [
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-13-2023/IMG_5310.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-13-2023/IMG_5318.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-13-2023/IMG_5319.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-13-2023/IMG_5325.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-13-2023/IMG_5329.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-13-2023/IMG_5331.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-13-2023/IMG_5337.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-13-2023/IMG_5339.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-13-2023/IMG_5351.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-13-2023/IMG_5353.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-13-2023/IMG_5376.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-13-2023/IMG_5388.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-13-2023/IMG_5394.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-13-2023/IMG_5399.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-13-2023/IMG_5400.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-13-2023/IMG_5417.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-13-2023/IMG_5437.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-13-2023/IMG_5458.JPG",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-13-2023/IMG_5471.jpg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-13-2023/slitscan-7560-364.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-13-2023/slitscan-9072-7853.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-13-2023/slitscan-18144-114.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-13-2023/slitscan-59421-5096.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-13-2023/trainscan.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-13-2023/trainscan2.jpeg",
        caption: " ",
      },
    ],
  },
  "6/14/2023": {
    day: "wednesday",
    journal:
      "a day consisting of: taking plant photos and finding even more types the more i looked (and getting sort of overwhelmed), pioneer bluffs, scenic outlook, beautiful clouds in the sky, council grove, flint hills bookstore, black watercolor grass explorations, and a mysterious disappearance of my hdmi cord. i will keep today's log short, but i learned a lot today and it wasn't what i would have expected at all.",
    pictures: [
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-14-2023/IMG_5472.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-14-2023/IMG_5485.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-14-2023/IMG_5492.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-14-2023/IMG_5511.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-14-2023/IMG_5534.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-14-2023/IMG_5535.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-14-2023/IMG_5537.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-14-2023/IMG_5539.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-14-2023/IMG_5540.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-14-2023/IMG_5551.jpeg",
        caption: " ",
      },
    ],
  },
  "6/15/2023": {
    day: "thursday",
    journal:
      "in the morning, my hdmi cord mysteriously appeared in the grass and amma found it while watching the sunrise. later in the morning, i took another visit to the preserve and took lots of plant photos from the trail. i am hoping to use these photos as references for my paintings. after i got back, i took a walk to the school. along the way i met linda and her grandson, carson and they were very nice! once i got to the school, i got to chat with folks there including matt, and i learned about his printmaking and the school and more! meanwhile, elliot was deconstructing various electronics and lida found a rock that looked like ice cream and drew she sprinkles on it :) also got to watch a rehearsal for an upcoming french music festival. once back at the station, i set up the train projection again and took more recordings and also got to enjoy some chicken curry that my mom made! also got to chat more with jeremiah about the kansas newspaper project!",
    pictures: [
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-15-2023/IMG_4907.JPG",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-15-2023/IMG_1052.jpg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-15-2023/IMG_1062.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-15-2023/IMG_5645.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-15-2023/IMG_5647.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-15-2023/IMG_5648.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-15-2023/IMG_5649.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-15-2023/IMG_5651.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-15-2023/IMG_5652.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-15-2023/IMG_5657.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-15-2023/IMG_5658.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-15-2023/IMG_5661.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-15-2023/IMG_5665.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-15-2023/IMG_5668.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-15-2023/IMG_5672.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-15-2023/IMG_5673.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-15-2023/IMG_5674.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-15-2023/IMG_5677.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-15-2023/IMG_5683.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-15-2023/IMG_4979.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-15-2023/IMG_5697.jpeg",
        caption: " ",
      },
    ],
  },
  "6/16/2023": {
    day: "friday",
    journal:
      "today i worked on some more painting development and prepared for tomorrow's workshop, mainly writing the instructions and other details. in the afternoon, we visited diana's studio and got to meet others from matfield. it was really interesting to here about her journey as an artist, painting in different styles based on her stages of life and how her current series was based on observing the prairie from a ground level. after squatting to take many pictures during this week, my thigh muscles certainly understand how the difference in perspective can be quite significant and intriguing. in the evening, i also did more train recordings and edited them too!",
    pictures: [
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-16-2023/IMG_5011.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-16-2023/IMG_5719.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-16-2023/IMG_5720.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-16-2023/IMG_5727.jpg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-16-2023/IMG_5734.jpg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-16-2023/IMG_5766.jpeg",
        caption: " ",
      },
    ],
  },
  "6/17/2023": {
    day: "saturday",
    journal:
      "after packing things up for the workshop, we headed over to the preserve to set up. the workshop was nice and it was cool to see familiar faces again and meet people walking through. i really enjoyed getting the chance to observe with everyone. after all of this was done, i was quite tired and luckily got to eat a nice meal to recover. after this, we visited the clements bridge. in the evening, i worked on some more paintings and code before a heavy storm rolled in around midnight.",
    pictures: [
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-17-2023/IMG_5772.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-17-2023/IMG_5774.jpg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-17-2023/IMG_5779.jpg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-17-2023/IMG_5793.jpg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-17-2023/IMG_5794.jpg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-17-2023/IMG_5806.jpg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-17-2023/IMG_5825.jpg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-17-2023/IMG_5853.jpg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-17-2023/IMG_5855.jpg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-17-2023/IMG_5866.jpg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-17-2023/IMG_5870.jpg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-17-2023/IMG_5920.jpg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-17-2023/IMG_5931.jpg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-17-2023/IMG_5936.jpg",
        caption: " ",
      },
    ],
  },
  "6/18/2023": {
    day: "sunday",
    journal:
      "in the morning i painted again and worked on developing some other project ideas. in the afternoon, we headed to the sandzen gallery where it was nice to see the opening of several exhibits and got to meet kelly and clive. on the way back home on a small road, we ran into indian cyclist sundaram narayanan who was biking in a race from portland to virginia. later in the afternoon, we got to say bye to derek who brought us some lovely homemade icecream. then we said bye to julia, bill, and charlie and also met pat who rebuilt matfield station. after this time kept moving on and i wrapped up my last bit of work (for now) and then started packing everything up before the long drive back tomorrow. ",
    pictures: [
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-18-2023/IMG_5942.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-18-2023/IMG_5944.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-18-2023/IMG_5947.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-18-2023/IMG_5948.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-18-2023/IMG_5956.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-18-2023/IMG_5960.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-18-2023/IMG_5970.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-18-2023/IMG_5975.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-18-2023/IMG_5250.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-18-2023/IMG_5252.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-18-2023/IMG_5981.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-18-2023/IMG_5255.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-18-2023/IMG_5983.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-18-2023/IMG_5988.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-18-2023/IMG_5996.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-18-2023/IMG_5997.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-18-2023/IMG_6001.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-18-2023/IMG_6005.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-18-2023/IMG_6008.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-18-2023/IMG_6009.jpeg",
        caption: " ",
      },
    ],
  },
  "6/19/2023": {
    day: "monday",
    journal:
      "in the early morning (for me), we moved everything into the car, took some last glances at the prairie and our surroundings and then attempted to head south. unfortunately, i took a wrong way on the road and ended up going northeast to the bazaar cattle pens instead of towards wichita and added an additional 30 mins to the 10.5 hr journey. however, after this it was fairly smooth and we took two stops: one in oklahoma for lunch and gas, and another south of fort worth for gas. the dandelion amma had picked had bloomed into a full sphere and we had arrived home.",
    pictures: [
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-19-2023/IMG_6023.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-19-2023/IMG_6027.JPG",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-19-2023/IMG_6029.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-19-2023/IMG_6043.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-19-2023/IMG_6044.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-19-2023/IMG_6052.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-19-2023/IMG_6056.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-19-2023/IMG_6058.jpeg",
        caption: " ",
      },
      {
        image: "https://vidyagiri.com/tallgrass-samples/log/06-19-2023/IMG_6068.JPG",
        caption: " ",
      },
    ],
  },
};
