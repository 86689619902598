import { createStyles, Container, Text, Group, SimpleGrid, rem } from "@mantine/core";
import * as React from "react";

// const image = require("../../data/computer_surfing_md_clr.gif");
const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: rem(100),
    paddingBottom: rem(40),
  },

  title: {
    // fontWeight: 900,
    fontSize: rem(34),
    marginBottom: theme.spacing.md,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(32),
    },
  },

  control: {
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
    },
  },

  mobileImage: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  desktopImage: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
}));

export function AboutPage() {
  const { classes } = useStyles();

  return (
    <Container className={classes.root}>
      <div>
        {/* <Text className={classes.title}>sample your surroundings</Text> */}
        <Text className={classes.title}>about the workshop</Text>

        <Text mt="md">
          sample your surroundings:
          <br />
          <i>a drop-in workshop</i>
        </Text>
        <Text mt="md">
          where: limestone barn, tallgrass prairie national preserve
          <br />
          when: saturday, june 17th 2023, 1-3 pm
        </Text>
        <Group mt={30}>
          <Text size="sm">
            <a href="workshop"> workshop instructions</a>
          </Text>
          {/* <Text size="sm">
            <a href="contribute"> contribute samples</a>
          </Text> */}
          <Text size="sm">
            <a href=" https://vidyagiri.com/tallgrass-samples/site/sample-your-surroundings-workshop-sheet.pdf">
              download instructions as pdf
            </a>
          </Text>
        </Group>
      </div>
      <br></br> <br></br>
      <SimpleGrid spacing={80} cols={2} breakpoints={[{ maxWidth: "sm", cols: 1, spacing: 40 }]}>
        <div>
          <Group sx={{ height: "100%" }} spacing={20}>
            <Text size="sm">
              Join artist-in-residence <a href="https://vidyagiri.com"> Vidya Giri</a> for a free, drop-in workshop exploring different
              methods of "sampling" your environment and surroundings. Find Vidya at the{" "}
              <a href="https://www.nps.gov/tapr/index.htm">Tallgrass Prairie National Preserve</a>. Supplies will be provided, but you're
              welcome to bring your own sketchbook and drawing tools.
            </Text>
            <Text size="sm">
              Vidya Giri is an artist, designer, and engineer from Houston, TX. Her art is reflective of her background: balanced between
              cultures, environments, and disciplines. Her work has spanned between online spaces, printed media, projection, and painting
              on physical and digital canvases. Her explorations revolve around collecting from one’s surroundings as a form of reflection
              and the parallels between natural and human-made identities and the environments they encompass.
            </Text>

            <Text size="sm">
              Some of her notes from this residency are logged <a href="../log">here</a>. She has worked on a few different projects while
              here in the Flint Hills including: <a href="https://vidyagiri.com/grasstrainsky">grasstrainsky</a>,{" "}
              <a href="https://vidyagiri.com/cyclochloris">cyclochloris</a> (a watercolor series focused on prairie grasses), and this
              workshop: sample your surroundings.
            </Text>

            <Text size="sm">
              {"The "}
              <a href="https://tallgrassartistresidency.org/">Tallgrass Artist Residency</a> is a partner program of the Kansas Creative
              Arts Industries Commission and is funded in part by the National Endowment for the Arts.
            </Text>
          </Group>
        </div>
        {/* <Image src={image} className={classes.mobileImage} />
        <Image src={image} className={classes.desktopImage} /> */}
      </SimpleGrid>
    </Container>
  );
}
