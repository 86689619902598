export const StepsList = [
  {
    id: "1",
    image: "https://vidyagiri.com/tallgrass-samples/site/yellow.png",
    label: "step #1: reflect",
    description: "reflect and observe your surroundings",
    content:
      "gather your materials from the barn and go out and find a place to observe. try to spend a few minutes absorbing your surroundings. you can choose to focus on the larger scale of your site or focus on small details. what can you observe with your senses? try to imagine how you might process your surroundings as if you had never seen them before or might never see them again.",
  },

  {
    id: "2",
    image: "https://vidyagiri.com/tallgrass-samples/site/green.png",
    label: "step #2: sample",
    description: "sample from your surroundings",
    content:
      "now that you have reflected on your site, you can take your samples! use your sampling process as a way to reflect on your surroundings. you can sample with any of the methods listed here, either on paper or with your digital device. some ways to sample on paper are: sketches (pencil, paint, abstracted forms, doodles) or written reflection (poems, paragraphs, bullet points, fragmented thoughts). some ways to sample with your digital device are: photo, video, or sound recordings. for inspiration, you can check out some of the examples or randomized prompts below!",
  },

  {
    id: "3",
    image: "https://vidyagiri.com/tallgrass-samples/site/blue.png",
    label: "step #3: share",
    description: "share and contribute your collected samples",
    content:
      "after reflecting and collecting your samples, you can share your findings with others at the barn. if you are interested in contributing your samples to this collective website, you can navigate to the 'contribute' page of this site and fill out the form and your samples will be added! if you are having issues with the form, Vidya can help you contribute your samples in another way.",
  },
];
