import { createStyles, Grid, Container, Text, Group, rem, useMantineTheme } from "@mantine/core";
import * as React from "react";
import { CardsCarousel } from "./CardsCarousel";
import { fieldLogData } from "./FieldLogData";
import { useMediaQuery } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: rem(100),
    paddingBottom: rem(40),
  },

  title: {
    // fontWeight: 900,
    fontSize: rem(34),
    marginBottom: theme.spacing.md,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(32),
    },
  },

  control: {
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
    },
  },

  mobileImage: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  desktopImage: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
}));

export function FieldLogPage() {
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const entries = Object.keys(fieldLogData).map((key, i) => (
    <div key={key}>
      <Grid>
        <Grid.Col sm={2} md={1} lg={1}>
          <Text size="xs">{key}</Text>
          <Text size="xs" transform="lowercase">
            <i>{fieldLogData[key].day}</i>
          </Text>
        </Grid.Col>
        <Grid.Col sm={10} md={11} lg={11}>
          <Text size="sm">{fieldLogData[key].journal}</Text>
          <br />
          {!mobile ? <CardsCarousel pictureData={fieldLogData[key].pictures} /> : <></>}
        </Grid.Col>
      </Grid>
      <br />
      <br />
    </div>
  ));

  return (
    <Container className={classes.root}>
      <div>
        <Group sx={{ height: "100%" }} spacing={20}>
          <Text className={classes.title}>vidya's field log</Text>
          <Text size="xs">
            <i>{"{ vidya's informal notes from the tallgrass residency}"}</i>
          </Text>
          <Text size="xs"></Text>
          <div>{entries}</div>
        </Group>
      </div>
    </Container>
  );
}
