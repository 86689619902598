import * as React from "react";
import { createRoot } from "react-dom/client";
import { Routes, Route } from "react-router-dom";

import { HeaderNav } from "./components/HeaderNav";
import { MantineProvider } from "@mantine/core";
import { NotFoundPage } from "./components/NotFoundPage";
import { AboutPage } from "./pages/about/AboutPage";
import { WorkshopPage } from "./pages/workshop/WorkshopPage";

import { HomePage } from "./pages/home/HomePage";
import { SamplePage } from "./pages/samples/SamplePage";
import SampleDetailPage from "./pages/samples/SampleDetailPage";
import { FieldLogPage } from "./pages/fieldlog/FieldLogPage";
import { ContributePage } from "./pages/contribute/ContributePage";

export default function App() {
  return (
    <>
      <MantineProvider
        theme={{
          fontFamily: "Noto Serif JP, serif",
          fontFamilyMonospace: "Noto Serif JP, serif",
          headings: { fontFamily: "Noto Serif JP, serif" },
          // colors: {
          //   brand: ["#144eff", "#144eff", "#144eff", "#144eff", "#144eff", "#144eff", "#144eff", "#144eff", "#144eff", "#144eff"],
          // },
          // primaryColor: "brand",
        }}
        // children={undefined}
      >
        <HeaderNav />

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="log" element={<FieldLogPage />} />
          <Route path="workshop" element={<WorkshopPage />} />
          <Route path="contribute" element={<ContributePage />} />
          <Route path="samples" element={<SamplePage />} />
          <Route path="/sample/:id" element={<SampleDetailPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </MantineProvider>
    </>
  );
}

export function renderToDom(container) {
  createRoot(container).render(<App />);
}
