import { SimpleGrid, Group, Avatar, Text, Accordion, Image, Card, createStyles } from "@mantine/core";
import * as React from "react";

interface AccordionLabelProps {
  id: string;
  label: string;
  image: string;
  description: string;
}

function AccordionLabel({ id, label, image, description }: AccordionLabelProps) {
  const { classes } = useStyles();
  return (
    <Group noWrap>
      <Avatar src={image} radius="xs" size="lg" />
      <div>
        <Text className={classes.body} size="lg">
          {label}
        </Text>
        <Text className={classes.body} size="sm">
          {description}
        </Text>
      </div>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  card: {
    // opacity: "0",
    // height: "45vh",
    background: "rgba(255,255,255,20)",
    transition: "transform 150ms ease, box-shadow 150ms ease",

    "&:hover": {
      transform: "scale(1.01)",
      boxShadow: theme.shadows.md,
    },
  },
  body: {
    color: "#545122",
  },
}));

export function AccordionDemo(props) {
  const { classes } = useStyles();
  const items = props.data.map((item) => (
    <Accordion.Item value={item.id} key={item.label}>
      <Accordion.Control>
        <AccordionLabel {...item} />
      </Accordion.Control>
      <Accordion.Panel>
        <Text className={classes.body} size="sm">
          {item.content}
        </Text>
        {/* <Text size="sm">{item.examples}</Text> */}
        <SimpleGrid cols={3} breakpoints={[{ maxWidth: "md", cols: 1 }]}>
          {item.examples?.map((props, key) => (
            <div>
              <br />
              <Card
                p="md"
                radius="md"
                component="a"
                href={item.examples[key].id !== "" ? "../sample/" + item.examples[key].id : ""}
                className={classes.card}
              >
                {item.examples[key].image !== "" ? (
                  <>
                    <Image height={"32vh"} src={item.examples[key].image} /> <br />
                  </>
                ) : (
                  <></>
                )}

                <Text className={classes.body}>{item.examples[key].title}</Text>
              </Card>
            </div>
          ))}
        </SimpleGrid>
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <Accordion chevronPosition="right" variant="contained">
      {items}
    </Accordion>
  );
}
